#query {
    display: grid;
    grid   :
      'search-bar newquery'71px 'message-list query-message-list'1fr 'query-container query-form'78px / 275px 1fr;
    height          : 75vh;
    background-color: '#00378';
    border-radius   : 10px;
  }


  

#searchbar{
    display: flex;
    align-items: center;
    grid-area: search-container;
   // background: #EEE;
    padding: 0 20px;
    border-radius: 10px 0 0 0;
    box-shadow: 0 1px 3px -1px #000000bf;
    z-index: 1;

    input {
        width: 0;
        flex: 1 0 0;
        color: black;
        outline: none;
        //font-weight: bold;
        border-radius: 2px;
        height: 30px;
        border: 0;
        padding-left: 48px;
        padding-right: 20px;
        //font-size: 1.4rem;
        //background: url('../../../images/search/search.svg') no-repeat $search-background-image-color;
        background-position: 15px center;
        background-size: 20px 20px;
    }
    
    input::placeholder {
        color: #DDD;
        font-weight: bold;
    }
}

.conver {
    display: grid;
    grid-template-columns: 40px 1fr max-content;
    grid-gap: 10px;
    //color: #FFF; // $contrasting-primary-text-color;
    font-size: 1.3rem;
    border: 1px solid #EEE;
   // border: 1px solid #FFFFFF;
    //padding: 20px 20px 20px 15px;
    padding: 3px 2px 3px 2px;
    color: black;;

  &.active,
  &:hover {
    background: #336CFF ;
    color: #FFF;
    //background: #0048AA;
  }
  
  &:hover {
    cursor: pointer;
  }
  
  > img {
    grid-row: span 2;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  .titletext {
    font-weight: bold;
    font-size: 14px;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
  
  .createddate {
    color:#DDD;
    white-space: nowrap;
    font-size: 12px;
    margin-left: 80px;
  }

  .conversationmessage {
    grid-column: span 2;
    padding-left: 5px;
    white-space: nowrap;
    overflow-x: hidden;
    font-size: 12px;
    text-overflow: ellipsis;
  }
}

.inde{
  //font-family: Roboto-Bold;
font-size: 14px;
color: #000000;
letter-spacing: 0;
font-weight: 700;
}
.subind{
  font-family: Roboto-Regular;
font-size: 12px;
color: #9B9B9B;
letter-spacing: 0;
font-weight: 400;
}

