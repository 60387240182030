// .vis-timeline {
//     //background-color: rgb(3, 3, 49);
//     // -webkit-text-fill-color: white;
// }
.vis-label {
    background-color: rgb(3, 3, 49);
}

.vis-item {
    opacity: 0.9;
    border-width: 2px;
    //    // -webkit-text-fill-color: black;
    // position: absolute;
    // color: #1a1a1a;
    // border-color: #97b0f8;
    // border-width: 1px;
    // background-color: #d5ddf6;
    // display: inline-block;
}
.vis-item.vis-dot {
    position: absolute;
    padding: 0;
    border-width: 4px;
    border-style: solid;
    border-radius: 4px;
}
.vis-item.vis-point.vis-selected,
.vis-item.vis-selected {
    background-color: #fff785;
}
.vis-tooltip {
    opacity: 0.8;
    border-width: 1px;
    -webkit-text-fill-color: black;
}

// .vis-item.red {
//     // border-color: black;
//     background-color: rgb(204, 9, 9);
//     opacity: 0.9;
//     -webkit-text-fill-color: whitesmoke;
// }

// .vis-item.green {
//     opacity: 1;
//     border-color: greenyellow;
// }

// .vis-item.green.vis-selected {
//     // background-color: rgba(255, 255, 255, 0);
// }
.item {
    width: 10px;
    background-color: blue;
}
.vis-item-content {
    white-space: nowrap;
    box-sizing: border-box;
    padding: 5px;
}
.vis-item.vis-box {
    text-align: center;
    border-style: solid;
    border-radius: 2px;
}
//   .vis-item.darkblue {
//     border-color: rgb(223, 211, 255);
//     background-color: rgb(5, 8, 63);
//     opacity: 0.9;
//     -webkit-text-fill-color: whitesmoke;
//   }

//   .vis-item.lightblue {
//     border-color: rgb(223, 211, 255);
//     background-color: rgb(98, 171, 219);
//     opacity: 0.9;
//   }

///resizable
.react-resizable {
    position: relative;
}
.react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
    //background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
}
/* demo */

.boxss {
    display: inline-block;
    border:0.5px dashed rgb(97, 97, 97);
    //border: 1px solid black;
   // text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 1px;
}
.layoutRoot {
    display: flex;
    flex-wrap: wrap;
  }

  ////resizablecss
  .Apps {
    //padding: 16px 8px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .tile {
    height: 100%;
    width: 100%;
    padding: 8px;
    //min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-top;
    border-radius: 8px;
  }
  

  
  #root {
    margin: 0 auto;
    height: 100%;
    width: 100%;
  }
  
  .splits {
   // margin: 36px 0;
    width: 100%;
    height: 100%;
   // max-height: 500px;
   min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //border: 1px solid #794d1f;
    //border:0.5px dashed rgb(97, 97, 97);
    border-radius: 8px;
  }
  .__dbk__gutter.Horizontal {
    height: 100%;
    padding: 0px !important;
    /* padding: 0 2px; */
    /* flex-direction: column; */
}
.__dbk__gutter.Dark {
    background: grey !important;
}
.__dbk__child-wrapper {
    min-width: 250px;
}