@import './variables';

html {
  -webkit-font-smoothing : 'antialiased';
  -moz-osx-font-smoothing: 'grayscale';
  height                 : '100%';
  width                  : '100%';
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  color                  : $gray;
  background-color       : '#f4f6f8';
  height                 : '100%';
  width                  : '100%';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: '100%';
  width : '100%';
}

.exa {
  //width: 400px;
  //height: 750px;
  height: 600px;

  //border: 1px solid #EEE;
  .cont {

    //width: 100px;
    height: 800px;
  }
}