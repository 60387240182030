.cards {
  height         : 100%;
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
}

// ul li {
//     display: inline-block;
//     padding: 6px;
//     margin-top: 0px;
//     font-size: 12px;
// }
p {
  font-size: 12px;
}

h5 {
  font-size: 14px;
  padding  : 4px;
}

tr {
  border: 1px solid #F9F9F9;
  ;
}

.ull {
  -webkit-column-count: 2;
  -moz-column-count   : 2;
  column-count        : 2;

}

.thead {

  font-size     : 12px;
  color         : #7E7E7E;
  letter-spacing: 0;
  text-align    : left;
  font-weight   : 400;
}

.trow {

  font-size     : 14px;
  color         : #000000;
  letter-spacing: 0;
  text-align    : left;
  font-weight   : 400;
}


.para {

  font-size   : 14px;
  color       : #000000;
  //text-align: left;
  font-weight : 400;
}

.t {

  color      : #000000;
  text-align : left;
  font-weight: 400;
}

.ss {
  // margin-left: 45px;
  position  : absolute;
  color     : white;
  margin-top: 8px;
}

.mdhe {
  font-size     : 12px;
  color         : #000000;
  letter-spacing: 0;
  //text-align: left;
  font-weight   : 400;
}

p.test2 {
  writing-mode  : vertical-rl;
  font-size     : 24px;
  color         : #005DFF;
  letter-spacing: 10;
  vertical-align: middle;
  font-weight   : 700;
  position      : relative;
}

.ca {
  background   : #FFFFFF;
  border       : 1px solid #E3E3E3;
  border-radius: 4px;

}


.moby {
  position         : relative;
  padding-left     : 37px;
}

.h21 {
  writing-mode: vertical-rl;
  position    : absolute;
  left        : 0;
  // top: 0;
  line-height : 0;
  margin-top  : 300px;
  //font-family : Roboto-Bold;
}

.h221 {
  writing-mode: vertical-rl;
  position    : absolute;
  //left        : 0;
  // top: 0;
  line-height : 0;
  margin-top  : 300px;
  //font-family : Roboto-Bold;
}

.cc {
  font-size     : 24px;
  color         : #005DFF;
  letter-spacing: 200;
  //text-align: center;
  font-weight   : 700;
  margin-left: 0px;
}


.App {
  // text-align: center;
  // max-width: 728px;
  margin: 0 auto;
}

.header {
  overflow: hidden;
 // background-color: #f1f1f1;
  padding: 3px 3px;
  margin-top: 5px;
}
.header1 {
  overflow: hidden;
 // background-color: #f1f1f1;
  padding: 3px 3px;
  //margin-top: 5px;
}
.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

ul,
li {
  text-align: left;
  list-style: none;
}

.p {
  max-width: 500px;
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
}

.message {
  display: flex;
  align-items: center;
}

.sent {
  flex-direction: row-reverse;
}

.sent p {
  color: white;
  background: #0b93f6;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.imgg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}

.chatplace {
  //width: 400px;
  height: 430px;
  border: 3px solid #eee;
  border-radius: 5px;

  .chatcontent {
      //background: green;
      //width: 800px;
      height: 500px;
  }
}
#flowBoxes {
  margin:auto;
  //padding:20px;
  //min-width:700px;

}
#flowBoxes div {
  display:inline-block;
  position:relative;
  height:25px;
  //line-height:25px;
  line-height:19px;
  padding:0 20px;
  border:1px solid #ccc;
  margin-right:2px;
  background-color:white;
}

#flowBoxes div.right:after{
  content:'';
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
  width:18px;
  height:18px;
  position:absolute;
  right:0;
  top:-1px;
  background-color:white;
  z-index:150;
  
  -webkit-transform: translate(10px,4px) rotate(45deg);
     -moz-transform: translate(10px,4px) rotate(45deg);
      -ms-transform: translate(10px,4px) rotate(45deg);
       -o-transform: translate(10px,4px) rotate(20deg); 
          transform: translate(10px,4px) rotate(45deg);
}

#flowBoxes div.left:before{
  content:'';
  border-top:1px solid #ccc;
  border-right:1px solid #ccc;
  width:18px;
  height:18px;
  position:absolute;
  left:0;
  top:-1px;
  background-color:white;
  z-index:50;
  
  -webkit-transform: translate(-10px,4px) rotate(45deg);
     -moz-transform: translate(-10px,4px) rotate(45deg);
      -ms-transform: translate(-10px,4px) rotate(45deg);
       -o-transform: translate(-10px,4px) rotate(20deg);
          transform: translate(-10px,4px) rotate(45deg);
}
#flowBoxes .active{
  background-color:green;
  color:white;
}
#flowBoxes div.active:after{
  background-color:green;
}
